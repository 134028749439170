@import '~texkit/dist/styles/variables';
@import '~texkit/dist/styles/mixins';
@import '~texkit/dist/styles/placeholders';

.payment {
  &-card {
    display: flex;
    flex-direction: column;
    align-items: left;
    font-weight: 500 !important;
    margin: $margin 0 8px;    

    @media #{$mq-min-sm} {
      align-items: center;
      flex-direction: row;
      flex-basis: 90%;
    }

    .credit-card-icon {
      margin: 0 0 ($margin * 2) 0;

      @media #{$mq-min-sm} {
        margin: 0;
      }
    }

    &-column {
      display: flex;
      flex-direction: column;
    }

    &-info {
      display: flex;
      align-items: flex-end;

      @media #{$mq-min-sm} {
        margin: 0 $margin 0 ($margin * 2);
        flex: 1;
      }
    }

    &-selected {
      font-weight: bold;
      color: #47525D;
    }

    &-name {
      font-size: $font-size-sm;
      color: #47525D;
      text-align: left;
    }


    &-number {
      font-size: $font-size-lg;
    }

    &-expiration {
      font-size: $font-size-sm;
      letter-spacing: 0.04em;
      color: #47525D;
    }

    .text-danger {
      color: #D2082D;
    }

    &-security-code {
      max-width: 200px;
    }

    .credit-card-icon {
      font-size: 56px;
    }
  }

  &-select {
    &-account {
      &-title {
        margin-top: 0;
      }
    }

    &-card {
      margin: 0;
      padding: 0;
    }

    &-list {
      &-item {
        margin: 0;
        padding: 0 $padding $padding 0;
        border-bottom: 1px solid $color-gray-lighter;

        @media #{$mq-min-sm} {
          padding: 0 $padding $padding $padding;
        }

        &:last-child {
          border-bottom: 1px solid $color-gray-lighter;
        }

        .input-wrapper-field {
          display: flex;
          align-items: center;
        }

        .input-label {
          width: 100%;
          padding-top: 0;

          a {
            font-weight: 500;
          }

          a:hover {
            text-decoration: underline !important;
          }

          &:before {
            top: 36px;
          }

          &:after {
            top: 48px;
          }
        }

        .payment-select-card {
          margin: 0;
          padding: 0;
        }
      }

      .input-radio-group {
        margin-bottom: 0;
      }
    }

    .payment-card-info {
      margin-left: -38px;
      @media #{$mq-min-sm} {
        margin-left: ($margin * 2);
      }
    }

    .payment-add {
      @media #{$mq-min-sm} {
        padding: 0 ($padding * 1.5) 0 ($padding * 3.5);
      }
    }
  }  

  .payment-select-list-item.disabled .input-label:before {
    background-color: #DCDEE1;
    border-color: #C2C6CC;
  }

  &-list {
    &-item {
      align-items: left;
      border-bottom: 1px solid $color-gray-lighter;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      position: relative;

      @media #{$mq-min-sm} {
        flex-direction: row;
        align-items: center;
      }

      &-icon {
        flex-basis: 10%;
      }

      &-more {
        color: $color-gray;
        position: absolute;
        right: 0;
        top: 10px;
      }
      &-more-blue-svg {
        color: #304ffe;
        position: absolute;
        right: 0;
        top: 10px;
      }
    }
  }

  &-add {
    @extend %button-reset;

    width: 100%;
    display: flex;
    align-items: center;
    flex: 1;
    padding-left: 0;

    &-wrapper {
      font-weight: 500 !important;
      margin: $margin 0;
      padding: 0 0 $padding 0;
      border-bottom: 1px solid $color-gray-lighter;
    }

    .credit-card-icon {
      font-size: 56px;
    }

    &-text {
      margin-left: $margin;

      @media #{$mq-min-sm} {
        margin-left: $margin * 2;
      }
    }

    &-icon {
      @include resizeIcon(40px);
      color: $color-green-light;

      @media #{$mq-min-sm} {
        margin-right: $margin;
      }
    }
  }

  &-review {
    &-subtitle {
      margin-top: 0;
    }
  }
}

/* Temporary until Texkit form compoent update */
.payment-add-text {
  font-weight: 400;
} 

.tx-payment-card-form {  
  &__label__text,
  .tx-input__label__text {
    font-weight: 400;
  }  
}

.selected-payment {
  background-color: #F6F9FD;
}
.payment-button-outline {
  color: #304ffe;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  box-sizing: border-box;
  border: 2px solid rgba(48, 79, 254, 0.8);
  background-color: #ffffff;
}

.payment-card-security-code {
  label {
    padding-left: 0 !important;
    font-weight: 500;
  }
}

#cvv {
  opacity: unset;
  height: 56px;
}

/*  Payment information - cards tooltip Styles */
.popover {
  display: block;
  top: 30px !important;
  left: 30px !important;
  margin: auto;
}

.popover.top {
  margin-top: 0px;
}

.popover .arrow,
.popover .arrow:after {
  position: absolute;
  z-index: 1030;
  left: 80px;
  margin-top: 5px;
  width: 10px;
  height: 10px;
  background-color: #ffffff;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transform: rotate(45deg);
}

.popover-content {
  position: relative;
}

.popover-content > div {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 10px;
  left: 0;
  z-index: 1000;
  max-width: 276px;
  padding: 10px 1px 1px 14px;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
