@import '~texkit/dist/styles/variables';

.edit-payment-account-modal {
  .modal-body {
    padding: 16px 48px 0px;
  }
  
  .modal-title {    
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px; 
  }
  
  .payment-frame {
    height: 1460px;
    margin: 0 auto;

    @media #{$mq-min-sm} {
      height: 1180px;
    }
  }

  .iframe-error {
    margin-top: ( $margin * 3 );
    text-align: left;
  }

  .tx-form-actions__content {
    justify-content: center;
  }
}
