@import '~texkit/dist/styles/variables';

.add-payment-account-modal {
  .modal-title {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 20px;    
    line-height: 38px;
  }

  .payment-frame {
    height: 1475px;
    margin: 0 auto;

    @media #{$mq-min-sm} {
      height: 1445px;
    }
  }

  .tx-payment-card-form__header {         
    margin-bottom: 32px;
  }

  @media screen and (max-width: 600px) {
    .modal-title {
      font-size: 24px;
      font-weight: 700;
      line-height: normal;    
      line-height: normal;
    }

    .tx-payment-card-form__header {     
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
    }
  }  
}
