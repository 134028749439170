// Texkit UI
@import '~texkit-ui/styles/vars.css';

@import '~texkit-ui/styles/autocomplete.css';
@import '~texkit-ui/styles/alert.css';
@import '~texkit-ui/styles/button.css';
@import '~texkit-ui/styles/card.css';
@import '~texkit-ui/styles/checkbox.css';
@import '~texkit-ui/styles/form-group.css';
@import '~texkit-ui/styles/input.css';
@import '~texkit-ui/styles/loading-text.css';
@import '~texkit-ui/styles/modal.css';
@import '~texkit-ui/styles/radio-group.css';
@import '~texkit-ui/styles/radio.css';
@import '~texkit-ui/styles/select.css';
@import '~texkit-ui/styles/modal.css';
@import '~texkit-ui/styles/progress-bar.css';
@import '~texkit-ui/styles/payment-card-form.css';

.form-actions-item .tx-btn {
  width: 100%;
}

/* Input Code Styling */

.input-code .tx-input {
  margin: 16px auto 0;
  width: 264px;
}

.input-code .tx-input__label__input {
  font-weight: 400;
  text-align: center;
}

// TODO temporary fix - update styles in takeover-layout.scss
.tx-form-group__content .alert-container {
  padding-top: 0 !important;
}

// TODO temporary fix - update modal z-index to account for mobile header
.tx-modal__content {
  z-index: 2001;
}
